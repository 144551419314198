import {
  AfterViewInit,
  Component,
  HostListener, Inject, OnDestroy,
  OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, NavigationStart, Router, Event, RouterOutlet} from '@angular/router';
import {environment} from '@env/environment';
import {routeAnimations} from './shared/animations/animations';
import {AuthService, ToolsService, TrackingService, RafService, EventsService, TableOverflowManager} from './services/services';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';
import { DOCUMENT } from '@angular/common';
import {Subscription} from 'rxjs';
import en from '@app/en.json';
import fr from '@app/fr.json';
import {ClickOrigin} from '@app/models/global';
import {MetatagsService} from '@app/services/metatags/metatags.service';

const transitionIconMap = {
  anglais: 'passport',
  chimie: 'vial',
  'education-financiere': 'moneybag',
  francais: 'eraser',
  geographie: 'compass',
  histoire: 'canoe',
  mathematique: 'calculator',
  'monde-contemporain': 'bullhorn',
  physique: 'rainbow',
  science: 'loupe'
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:resize', ['$event.target'])
  onResize = debounce(this.setupTransitionStickers.bind(this), 500, false);
  @HostListener('window:scroll', ['$event.target'])
  onScrollEvent = throttle(this.onScroll.bind(this), 200, false);
  key: string;
  stickers: [any];
  topicSticker: string;
  prevScrollPos = window.pageYOffset;
  subscriptions: Subscription[] = [];
  authorized = false;
  lastNavigationTrigger: string;
  lastClickOrigin: ClickOrigin;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private auth: AuthService,
    private raf: RafService,
    private tableOverflow: TableOverflowManager,
    private tracking: TrackingService,
    public tools: ToolsService,
    @Inject(DOCUMENT) private document,
    private events: EventsService,
    private metaTagsService: MetatagsService
  ) {
    this.translate.setTranslation('fr', fr);
    this.translate.setTranslation('en', en);
    this.translate.use('fr');
    this.translate.setDefaultLang('fr');
    this.tools.setTheme('default');

    // hack for Ios :active pseudo element state
    const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (isIOS) {
      document.body.addEventListener( 'touchstart', () => {} );
    }

    this.subscriptions.push(
      this.router.events.subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          this.lastNavigationTrigger = event.navigationTrigger;
        } else if (event instanceof NavigationEnd) {
          this.metaTagsService.updateMetaTags(event.urlAfterRedirects);
          if (
            this.lastNavigationTrigger === 'popstate' &&
            this.lastClickOrigin &&
            this.lastClickOrigin.url === this.router.url
          ) {
            setTimeout(() => {
              this.tools.scrollTo({
                top: this.lastClickOrigin.scrollY,
                behavior: 'auto'
              });
              this.lastClickOrigin = null;
            }, 250);
          }
        }
      })
    );

    this.events.on('app:popstate:restore-scroll', this.onPopstateRestoreScroll);
  }

  ngOnInit() {
    window['onYouTubeIframeAPIReady'] = () => {
      this.tools.isYouTubeIframeAPIReady = true;
      this.events.broadcast('youtube-iframe-api-ready');
    };

    this.raf.start();

    this.setupTransitionStickers();

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          const contentInfo = this.tools.getContentInfo(event.url);
          this.tracking.pushVar({content_type: contentInfo.content_type});
          this.tracking.pushVar({content_id: contentInfo.content_id});

          this.useTransitionTopicSticker(event.url);

          this.tableOverflow.clearTables();
        }

        if (event instanceof NavigationEnd) {
          this.subscriptions.push(
            this.translate.use(event.url.startsWith('/en') ? 'en' : 'fr')
              .subscribe(() => this.tools.setLangAttr(this.translate.currentLang))
          );

          this.tools.state = event.url.startsWith('/parents') || event.url.startsWith('/en/parents')
            ? 'parents' : 'students';
          this.tools.setPortal(this.tools.state);
        }
      })
    );

    this.subscriptions.push(
      this.auth.getCurrentUser().subscribe(
        user => this.tracking.pushVar({emp_id: user ? user.id : null})
      )
    );

    if (environment.key) {
      const key = localStorage.getItem('key');
      if (key === environment.key) {
        this.authorize(key);
      } else {
        const paramsKey = new URLSearchParams(window.location.search).get('key');
        if (paramsKey === environment.key) {
          this.authorize(paramsKey, true);
        } else {
          this.router.navigate(['non-authorise']);
        }
      }
    } else {
      this.authorize();
    }
  }

  ngAfterViewInit(): void {
    const playerApiScript = this.document.createElement('script');
    playerApiScript.type = 'text/javascript';
    playerApiScript.src = 'https://www.youtube.com/iframe_api';
    this.document.body.appendChild(playerApiScript);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.events.on('app:popstate:restore-scroll', this.onPopstateRestoreScroll);
  }

  onScroll() {
    const currentScrollPos = window.pageYOffset;
    const scrollTop = this.tools.document.documentElement.scrollTop;
    const className = 'app__header';
    const navBar = this.tools.document.querySelector('.' + className);
    if (this.prevScrollPos > currentScrollPos) {
      navBar.classList.remove(className + '--out');
      navBar.classList.add(className + '--min');
      this.tools.setBodyAttribute('data-scroll-dir', 'up');
    } else {
      navBar.classList.add(className + '--out');
      this.tools.setBodyAttribute('data-scroll-dir', 'down');
    }
    if (scrollTop <= 0) {
      navBar.classList.remove(className + '--min');
      navBar.classList.remove(className + '--out');
      navBar.classList.add(className + '--max');
    } else {
      navBar.classList.remove(className + '--max');
    }
    this.prevScrollPos = currentScrollPos;
  }

  onPopstateRestoreScroll = () => {
    this.lastClickOrigin = {
      scrollY: window.scrollY,
      url: this.router.url
    };
  }

  authorize(key?: string, storeKey?: boolean) {
    if (key) {
      this.key = key;
      if (storeKey) { localStorage.setItem('key', key); }
    }
    this.authorized = true;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  onRouteAnimationDone() {
    this.tableOverflow.checkOverflowTables();
  }

  useTransitionTopicSticker(url) {
    const elements = url.split('/');
    const topic = elements[2] ? elements[2].split('?')[0] : undefined; // i.e. "/bv/chimie" or "bv/mathematique?keywords=triangle"

    this.topicSticker = transitionIconMap[topic] || 'rainbow';
  }

  setupTransitionStickers() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const widthBase = windowWidth <= 700 ? 175 : 350; // this is the width we're shooting for
    const widthDivider = windowWidth / widthBase; // how many can we fit in the viewport?
    const width = Math.round(windowWidth / widthDivider); // define the width based on how many we can fit right now
    const height = width;
    const shrinkRatioVertical = 1.8; // condense icons
    const shrinkRatioHorizontal = 1.3; // condense icons

    // define the number of stickers we'll be creating per row and per column
    const cols = Math.round(windowWidth / (width / shrinkRatioVertical));
    const rows = Math.round(windowHeight / (height / shrinkRatioHorizontal)) + 2; // add some...

    const adjustX = -5; // adjust so that they don't leave gaps on top and to the left of the viewport
    const adjustY = -10;

    this.stickers = [''];

    let x = 0;

    for (let i = 0; i <= rows; i++) {
      for (let j = 0; j <= cols; j++) {
        const animationDelay = `${Math.sin(j) * 200}ms`; // adding a custom delay to the floating animation, to create a waving effect
        // offset the position on every other row to create some funky patterns
        const marginLeft = i % 2 === 0
          ? `-${width / 2}px`
          : null;

        this.stickers[x] = {
          params: {
            oddCol: j % 2 !== 0,
            oddRow: i % 2 !== 0
          },
          styles: {
            width: width + 'px',
            height: height + 'px',
            top: (100 - i * Math.round(100 / rows)) + adjustY + '%',
            left: (100 - j * Math.round(100 / cols)) + adjustX + '%',
            marginLeft,
            zIndex: (cols * rows) - x,
          },
          imgStyles: {
            animationDelay
          }
        };

        x++;
      }
    }
  }
}
