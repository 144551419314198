import { NgModule } from '@angular/core';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MathJaxModule } from 'ngx-mathjax';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ AppComponent ],
  imports: [
    CoreModule,
    AppRoutingModule,
    MathJaxModule.forRoot({
      version: '2.7.6',
      config: 'TeX-MML-AM_CHTML',
      hostname: 'cdnjs.cloudflare.com'
    }),
    TranslateModule.forRoot()
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
}
