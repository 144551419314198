import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ContentService,
  EventsService,
  RafService,
  ToolsService,
  TrackingService,
} from '@app/services/services';
import { toggleAnimation } from '@app/shared/animations/animations';
import { SearchResults } from '@app/models/content';
import { Subscription } from 'rxjs';
import debounce from 'lodash.debounce';
import gsap from 'gsap';
import { TopicVal, FileType } from '@src/app/shared/enums';
import {NoSpacingBreakpoint, TileContext} from '@app/shared/enums';

const SEARCH_AS_YOU_TYPE_DELAY = 400;
const DEBOUNCE_HEIGHT_DELAY = 200;
const PREVENT_KEYS = {
  Enter: true,
  ArrowLeft: true,
  ArrowRight: true,
  ArrowUp: true,
  ArrowDown: true,
  Home: true,
  End: true,
  PageUp: true,
  PageDown: true,
  Alt: true,
  AltGraph: true,
  CapsLock: true,
  Control: true,
  NumLock: true,
  Shift: true,
  Tab: true,
  ' ': true,
};

// tslint:disable: member-ordering
@Component({
  selector: 'app-site-search',
  templateUrl: './site-search.component.html',
  styleUrls: ['./site-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [toggleAnimation],
})
export class SiteSearchComponent implements OnInit, OnDestroy {
  noSpacingBreakpoints = NoSpacingBreakpoint;
  tileContexts = TileContext;
  active = false;
  byTopic: object[] = [];
  isSearching = false;
  params = { keywords: '' };
  popularKeywords: string[];
  recentItems: object[] = [];
  results: SearchResults = {
    top: [],
    file: [],
    exercise: [],
    video: [],
  };
  searched = false;
  searchHistory: string[] = [];
  searchSuggestions: string[] = [];
  subscriptions: Subscription[] = [];
  transitions = {
    loading: { target: 0, interpolated: 0, speed: 0.15 },
    resetBtn: { target: 0, interpolated: 0, speed: 0.15 },

    searchHeight: { target: 0, interpolated: 0, speed: 0.15 },
    searchOpacity: { target: 0, interpolated: 0, speed: 0.2 },

    suggestions: { target: 0, interpolated: 0, speed: 0.15 },
    suggestionsOpacity: { target: 0, interpolated: 0, speed: 0.2 },

    loadingResults: { target: 0, interpolated: 0, speed: 0.15 },

    tagsByTopic: { target: 0, interpolated: 0, speed: 0.15 },

    topResults: { target: 0, interpolated: 0, speed: 0.15 },
    topResultsOpacity: { target: 0, interpolated: 0, speed: 0.2 },

    filesResults: { target: 0, interpolated: 0, speed: 0.15 },
    filesResultsOpacity: { target: 0, interpolated: 0, speed: 0.2 },

    videosResults: { target: 0, interpolated: 0, speed: 0.15 },
    videosResultsOpacity: { target: 0, interpolated: 0, speed: 0.2 },

    exercisesResults: { target: 0, interpolated: 0, speed: 0.15 },
    exercisesResultsOpacity: { target: 0, interpolated: 0, speed: 0.2 },

    noResults: { target: 0, interpolated: 0, speed: 0.15 },
    noResultsOpacity: { target: 0, interpolated: 0, speed: 0.2 },
    extras: { target: 0, interpolated: 0, speed: 0.15 },
    extrasOpacity: { target: 0, interpolated: 0, speed: 0.2 },
  };
  debounceTime: number = 750;

  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('tagKeywords') tagKeywords: ElementRef;
  @ViewChild('suggestionsContainer')
  suggestionsContainer: ElementRef;
  @ViewChild('topResultsContainer')
  topResultsContainer: ElementRef;
  @ViewChild('fileResultsContainer')
  fileResultsContainer: ElementRef;
  @ViewChild('videoResultsContainer')
  videoResultsContainer: ElementRef;
  @ViewChild('exerciseResultsContainer')
  exerciseResultsContainer: ElementRef;
  @ViewChild('noResultsContainer')
  noResultsContainer: ElementRef;
  @ViewChild('extrasContainer') extrasContainer: ElementRef;
  @ViewChild('tagByTopic') tagByTopic: ElementRef;

  constructor(
    private content: ContentService,
    private events: EventsService,
    private raf: RafService,
    private router: Router,
    public translate: TranslateService,
    private tools: ToolsService,
    private tracking: TrackingService,
  ) { }

  ngOnInit() {
    this.events.on('search:open', this.onOpen);
    this.events.on('search:close', this.onClose);
    this.raf.bind('site-search', this.draw.bind(this));
    window.addEventListener('resize', this.updateHeightsResults);
    window.addEventListener('resize', this.updateHeightsSugestions);
    this.onKeywordsKeyUp = debounce(this.onKeywordsKeyUp, this.debounceTime);


    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          this.clearSuggestions();
          this.onClose();
        }
      })
    );
  }

  ngOnDestroy() {
    this.events.off('search:open', this.onOpen);
    this.events.off('search:close', this.onClose);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.raf.unbind('site-search');
    window.removeEventListener('resize', this.updateHeightsResults);
    window.removeEventListener('resize', this.updateHeightsSugestions);


  }

  /**
   * Events
   */
  onClickClearKeywords() {
    this.setKeywords('');
    this.clearSuggestions();
    this.clearSearchResults();
    this.searchField.nativeElement.focus(); // focus the search field for a possible new search
  }

  onClickClose() {
    this.events.broadcast('search:close');
  }

  onClickClearHistory() {
    this.searchHistory = [];
  }

  onKeywordsKeyUp(e) {
    if (e.key === 'Escape') {
      return this.onClose();
    }
    if (e.target.value === '') {
      this.clearSearchResults();
      this.clearSuggestions();
      return;
    }
    if (!PREVENT_KEYS[e.key]) {
      this.transitions.loading.target = 1; // show loading spinners right away
      this.transitions.loadingResults.target = 1;
      this.search();
      this.getSearchSuggestions(e.target.value);
    }

    if (e.target.value.trim() !== '') {
      this.tracking.pushVar({
        category: 'recherche',
        label: e.target.value
      });
    }
  }

  onOpen = () => {
    if (this.active) {
      return;
    }
    this.active = true;
    this.searchField.nativeElement.focus();
    this.updateHeightsResults();
    this.updateHeightsSugestions();
  }

  onClose = () => {
    if (!this.active) {
      return;
    }

    this.events.broadcast('search:close-flo');

    gsap.delayedCall( .6, () => {
      this.active = false;
      this.updateHeightsResults();
      this.updateHeightsSugestions();
    });
  }

  /**
   * UI Updates
   */
  setKeywords(keywords: string) {
    this.params.keywords = keywords;
    this.search();
  }

  clearSuggestions() {
    this.transitions.loading.target = 0;
    this.transitions.loadingResults.target = 0;
    this.transitions.suggestions.target = 0;
    this.transitions.suggestionsOpacity.target = 0;

    this.searchSuggestions = [];
    this.updateHeightsSugestions();
  }

  clearSearchResults() {
    this.resetTransitions();

    this.isSearching = false;
    this.searched = true;

    gsap.delayedCall( .5, () => {
      this.results = {
        top: [],
        file: [],
        video: [],
        exercise: [],
      };
      this.updateHeightsResults();
    }); // give some time for the animation to finish before clearing the list
  }

  /**
   * Actions
   */
  getSearchSuggestions = debounce(() => {
    const { keywords } = this.params;
    const terms = keywords.trim();

    if (terms === '') {
      this.searched = false;
      // no need for any suggestion then
      return this.clearSuggestions();
    }

    this.transitions.suggestionsOpacity.target = 0;
    this.transitions.loading.target = 1;

    this.subscriptions.push(
      this.content.getSuggestions(terms).subscribe(suggestions => {
        this.searchSuggestions = suggestions.map(item => item.text);

        if (!this.searchSuggestions.length) {
          this.clearSuggestions();
        }

        this.transitions.loading.target = 0;
      })
    );
  }, SEARCH_AS_YOU_TYPE_DELAY);

  search = debounce(() => {
    const { keywords } = this.params;
    const terms = keywords.trim();

    if (terms === '') {
      return;
    }

    this.isSearching = true;
    this.searched = false;

    this.transitions.loadingResults.target = 1;
    this.transitions.topResultsOpacity.target = 0;

    this.setOtherResults(terms);
    this.setTopresults(terms);

  }, SEARCH_AS_YOU_TYPE_DELAY);

  setTopresults(terms) {
    this.subscriptions.push(
      this.content
        .searchFiles(this.translate.currentLang, terms)
        .subscribe(results => {
          this.results.top = results.tiles.slice(0, 8);
          this.searchResultsComplete(terms);
      })
    );
  }


  setTopResultsperCat(terms) {

    const topResults = [];
    for (const type in FileType) {
      if (FileType.hasOwnProperty(type)) {

        // Only way to get something like  FileType.file
        const types = FileType['' + type + ''];

        this.subscriptions.push(
          this.content
            .searchFiles(this.translate.currentLang, terms, types)
            .subscribe(results => {
              const result = results.tiles.slice(0, 1)[0];
              if (result) {
                topResults.push(result);
              }

              this.searchResultsComplete(terms);
          })
        );
      }
    }
    this.results.top = topResults;
  }

  setOtherResults(terms) {
    for (const type in FileType) {
      if (FileType.hasOwnProperty(type)) {

        // Only way to get something like  FileType.file
        const types = FileType['' + type + ''];

        this.subscriptions.push(
          this.content
            .searchFiles(this.translate.currentLang, terms, types)
            .subscribe(results => {

              if (type === 'file') {
                const filteredFiles = this.checkRevisionFilesResults(results.tiles);
                this.results[type] = filteredFiles.slice(0, 8);
              } else {
                this.results[type] = results.tiles.slice(0, 8);
              }
          })
        );
      }
    }
  }

  checkRevisionFilesResults(tiles) {
    const filteredFiles = tiles.filter(tile => {
      return tile.subtype !== 'revision';
    });

    return filteredFiles;
  }

  searchResultsComplete(terms) {

    this.isSearching = false;
    this.searched = true;

    this.transitions.loadingResults.target = 0;

    this.animateFlo();

    gsap.delayedCall(0.2, () => {
      this.updateHeightsResults();
    });

    this.setButtonList(terms);
  }

  setButtonList(terms) {
    this.content.getTotalResultsByTypeAndTopic(this.translate.currentLang, terms)
    .subscribe(results => {

      this.byTopic.length = 0;

      results.byTopic.forEach(result => {
        const {topic, size } = result;

        if (!TopicVal.hasOwnProperty(topic)) {
          return;
        }

        const tradTopic = this.translate.instant(`Common.Library.${topic}`);

        const newButtonData = {
          theme: topic,
          test : 'global-search-by-topics-' + topic,
          link: '/bv/' + this.tools.getTopicVal(topic),
          label: `${tradTopic} (${size})`,
          keywords: terms
        };

        this.byTopic.push(newButtonData);
      });

      this.transitions.loading.target = 0;

      this.updateHeightsSugestions();
    });
  }

  checkHasResults(): boolean {
    const { top, file, video, exercise } = this.results;
    return Boolean(
      top.length || file.length || video.length || exercise.length
    );
  }

  animateFlo() {
    const hasResults = this.checkHasResults();
    const animation = hasResults ? 'hasResults' : 'notFound';

    this.events.broadcast('flo:search:animate', animation);
  }



  updateHeightsSugestions = debounce(() => {
    const hasResults = this.checkHasResults();
    const {
      suggestionsOpacity, suggestions, tagsByTopic
    } = this.transitions;

    tagsByTopic.target = this.params.keywords && hasResults ? this.tagByTopic.nativeElement.clientHeight : 0;
    suggestionsOpacity.target = this.searchSuggestions.length ? 1 : 0;
    suggestions.target = this.searchSuggestions.length ? this.suggestionsContainer.nativeElement.clientHeight : 0;


  }, DEBOUNCE_HEIGHT_DELAY);

  updateHeightsResults = debounce(() => {
    const hasResults = this.checkHasResults();
    const {
      topResults, topResultsOpacity,
      filesResults, filesResultsOpacity,
      videosResults, videosResultsOpacity,
      exercisesResults, exercisesResultsOpacity,
      extras, extrasOpacity,
    } = this.transitions;
    const { top, file, video, exercise } = this.results;

    topResults.target = this.params.keywords && top.length ? this.topResultsContainer.nativeElement.clientHeight : 0;
    topResultsOpacity.target = this.params.keywords && top.length ? 1 : 0;

    filesResults.target = this.params.keywords && file.length ? this.fileResultsContainer.nativeElement.clientHeight : 0;
    filesResultsOpacity.target = this.params.keywords && file.length ? 1 : 0;

    videosResults.target = this.params.keywords && video.length ? this.videoResultsContainer.nativeElement.clientHeight : 0;
    videosResultsOpacity.target = this.params.keywords && file.length ? 1 : 0;

    exercisesResults.target = this.params.keywords && exercise.length ? this.exerciseResultsContainer.nativeElement.clientHeight : 0;
    exercisesResultsOpacity.target = this.params.keywords && file.length ? 1 : 0;

    extras.target = (this.recentItems.length || this.searchHistory.length) && !hasResults
      ? this.extrasContainer.nativeElement.clientHeight
      : 0;
    extrasOpacity.target = (this.recentItems.length || this.searchHistory.length) && !hasResults
      ? 1
      : 0;
  },  DEBOUNCE_HEIGHT_DELAY);

  resetTransitions() {
    const {
      loading,
      loadingResults,
      suggestionsOpacity,
      suggestions,
      topResults,
      topResultsOpacity,
      extras,
      extrasOpacity
    } = this.transitions;

    loading.target = 0;
    loadingResults.target = 0;
    suggestions.target = 0;
    suggestionsOpacity.target = 0;
    topResults.target = 0;
    topResultsOpacity.target = 0;
    extras.target = 0;
    extrasOpacity.target = 0;
  }

  animateInterpolated() {
    Object.keys(this.transitions).map(item => {
      const x = this.transitions[item];
      this.transitions[item].interpolated = this.tools.round(
        x.interpolated + (x.target - x.interpolated) * x.speed,
        9
      );
    });
  }

  draw() {
    this.animateInterpolated();
  }
}
