import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  ViewEncapsulation, AfterViewInit,
} from '@angular/core';
import { EventsService, ToolsService } from '@app/services/services';
import { TranslateService } from '@ngx-translate/core';
import {environment} from "@env/environment";
import {Topic} from "@app/shared/enums";

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('menu') menu: ElementRef;

  active = false;
  preventedScroll = false;
  list = [];

  constructor(
    private events: EventsService,
    public tools: ToolsService,
    public translate: TranslateService
  ) {

    let topics = this.tools.getEnumKeys('topics');

    // Todo: english test only there in case of going live in urgency
    if (environment.production) {
      topics = topics.filter(topic => topic !== Topic.english);
    }

    for ( let i = 0;  i < topics.length; i++ ) {

      const topic = topics[i];
      const tradTopic = this.translate.instant(
        `Common.Library.${topic}`
      );

      const newButtonData = {
        theme: topic,
        test : 'menu-students-topics-' + topic,
        link: '/bv/' + tools.getTopicVal(topic),
        label: tradTopic
      };
      this.list.push(newButtonData);
    }

  }

  ngOnInit() {
    this.events.on('header:open', this.onOpen);
    this.events.on('header:close', this.onClose);
    document.addEventListener('keyup', this.onKeyUp);
  }

  ngAfterViewInit(): void {
    this.menu.nativeElement.addEventListener('transitionend', this.onMenuTransitionEnd);
  }

  ngOnDestroy() {
    this.events.off('header:open', this.onOpen);
    this.events.off('header:close', this.onClose);
    document.removeEventListener('keyup', this.onKeyUp);
    this.menu.nativeElement.removeEventListener('transitionend', this.onMenuTransitionEnd);
  }

  onMenuTransitionEnd = () => {
    if (this.active && !this.preventedScroll) {
      this.tools.preventSiteScroll();
      this.preventedScroll = true;
    }
  }

  onOpen = () => {
    this.active = true;
  }

  onClose = () => {
    if (!this.active) {
      return;
    }

    this.tools.allowSiteScroll();
    this.preventedScroll = false;
    this.active = false;
  }

  onKeyUp = e => {
    if (e.key === 'Escape') {
      this.onClose();
    }
  }

  switchMenu() {
    //this.tools.toggleClass(this.menu.nativeElement, 'overlay--switch');

    const win = window.open('https://alloprofparents.ca', '_blank');
    win.focus();

  }
}
