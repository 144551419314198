import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot} from '@angular/fire/firestore';
import {FileType, TileType, TopicVal} from '@app/shared/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api/api.service';
import { environment } from '@src/environments/environment';
import { Level, HubSearchResults } from '@app/models/content';
import { getFileFullGQL } from '@app/services/content/gql-query-file';
import { getGameFullGQL } from '@app/services/content/gql-query-game';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private firestore: AngularFirestore,
    private translate: TranslateService,
    private api: ApiService
    ) { }


  public getLevelsFile(levels: any) {

    if ( !levels || !levels.length) {
      return null;
    }

    if (levels.length === 11) {
      return [
        { years: this.translate.instant('Common.all_levels') } as Level
      ];
    }
    const spans = {
      pr: [],
      sec: []
    };
    const levelsSorted = levels.sort((a, b) => a - b);
    let li = -1;
    const currentSpan = {
      from: undefined,
      to: undefined
    };
    while (++li < levelsSorted.length) {
      if (levelsSorted[li] < 7) {
        currentSpan.from = levelsSorted[li];
        while (levelsSorted[li + 1] === levelsSorted[li] + 1 && levelsSorted[li] < 6) {
          currentSpan.to = levelsSorted[1 + li++];
        }
        spans.pr.push(
          currentSpan.to > currentSpan.from
            ? '' + currentSpan.from + '-' + currentSpan.to
            : '' + currentSpan.from
        );
        currentSpan.from = undefined;
        currentSpan.to = undefined;
      } else {
        currentSpan.from = levelsSorted[li] - 6;
        while (levelsSorted[li + 1] === levelsSorted[li] + 1) {
          currentSpan.to = levelsSorted[1 + li++] - 6;
        }
        spans.sec.push(
          currentSpan.to > currentSpan.from
            ? '' + currentSpan.from + '-' + currentSpan.to
            : '' + currentSpan.from
        );
        currentSpan.from = undefined;
        currentSpan.to = undefined;
      }
    }

    const ret = [];
    let primTransKey = 'Common.primary';
    let secTransKey = 'Common.secondary';

    if (spans.pr.length) {
      ret.push({
        label: this.translate.instant(primTransKey),
        years: spans.pr.join(', ')
      } as Level);
    }
    if (spans.sec.length) {
      ret.push({
        label: this.translate.instant(secTransKey),
        years: spans.sec.join(', ')
      } as Level);
    }

    return ret;
  }

  public getLevels(file: any) {
    if (!file.ap_levels || !file.ap_levels.length) {
      return null;
    }
    if (file.ap_levels.length === 11) {
      return [
        { years: this.translate.instant('Common.all_levels') } as Level
      ];
    }
    const spans = {
      pr: [],
      sec: []
    };
    const levels = file.ap_levels.sort((a, b) => a - b);
    let li = -1;
    const currentSpan = {
      from: undefined,
      to: undefined
    };
    while (++li < levels.length) {
      if (levels[li] < 7) {
        currentSpan.from = levels[li];
        while (levels[li + 1] === levels[li] + 1 && levels[li] < 6) {
          currentSpan.to = levels[1 + li++];
        }
        spans.pr.push(
          currentSpan.to > currentSpan.from
            ? '' + currentSpan.from + '-' + currentSpan.to
            : '' + currentSpan.from
        );
        currentSpan.from = undefined;
        currentSpan.to = undefined;
      } else {
        currentSpan.from = levels[li] - 6;
        while (levels[li + 1] === levels[li] + 1) {
          currentSpan.to = levels[1 + li++] - 6;
        }
        spans.sec.push(
          currentSpan.to > currentSpan.from
            ? '' + currentSpan.from + '-' + currentSpan.to
            : '' + currentSpan.from
        );
        currentSpan.from = undefined;
        currentSpan.to = undefined;
      }
    }

    const ret = [];
    let primTransKey = 'Common.primary';
    let secTransKey = 'Common.secondary';
    if (spans.pr.length && spans.sec.length) {
      primTransKey = 'Common.primary_short';
      secTransKey =  'Common.secondary_short';
    }
    if (spans.pr.length) {
      ret.push({
        label: this.translate.instant(primTransKey),
        years: spans.pr.join(', ')
      } as Level);
    }
    if (spans.sec.length) {
      ret.push({
        label: this.translate.instant(secTransKey),
        years: spans.sec.join(', ')
      } as Level);
    }

    return ret;
  }

  public searchFiles(
    lang: string,
    keywords: string = null,
    type: TileType = null,
    topic: string = null,
    level: number = null,
    limit: number = 12,
    startAt: DocumentSnapshot<any> = null,
    startAtIndex: number = 0
    ): Observable<HubSearchResults> {
    if (keywords) { // ElasticeSearch query
      return new Observable((observer) => {
        const filters = [];
        // Filtres facets
        if (topic) {
          filters.push({ match: { ap_topic: topic } });
        }
        if (level) {
          filters.push({ match: { ap_levels: level } });
        }
        if (type && type === 'revision') {
          filters.push({ match: { type: 'file' } });
          filters.push({ match: { ap_subtype: 'revision' } });
        }
        if (type && type !== 'revision') {
          filters.push({ match: { type } });
        }
        if (lang) {
          filters.push({ match: { lang } });
        }
        // Filtre en fonction du mot du search
        filters.push({
          bool: {
            should: [
              { match: { title: keywords } },
              { match: { content: keywords } }
            ]
          }
        });
        const elasticQuery = {
          from : startAtIndex, size : limit + 1,
          query: {
            bool: {
              must: filters
          }
      }};
        this.api.post(environment.searchUrl + '/tiles', elasticQuery).subscribe(result => {
          return observer.next({
            tiles: this.resultsToTiles(result.tiles),
            size: result.total
        });
      }, err => {
        return observer.error(err);
      });
    });
    } else { // Firestore query
      return new Observable((observer) => {
        const query  = this.firestore.collection('content', ref => {
          let cond = ref.where('lang', '==', lang);
          if (topic) {
            cond = cond.where('ap_topic', '==', topic);
          }
          if (level) {
            cond = cond.where('ap_levels', 'array-contains', Number(level));
          }
          if (type && type === 'revision') {
            cond = cond.where('type', '==', 'file');
            cond = cond.where('ap_subtype', '==', 'revision')
          }
          if (type && type !== 'revision') {
            cond = cond.where('type', '==', type);
          }
          cond = cond.orderBy('hits', 'desc');
          if (startAt) {
            cond = cond.startAt(startAt);
          }

          cond = cond.limit(limit + 1);
          return cond;
        });
        query.get().subscribe(results => {
          const preTiles = results.docs.map(doc => doc.data());
          return observer.next({
            tiles: this.resultsToTiles(preTiles),
            size: results.size,
            next: results.docs[limit]
          });
        });
      });
    }
  }

  // Return keyword suggestiosn based on the searched term
  public getSuggestions(keyword: string) {
    return new Observable<any>((observer) => {
      if (keyword) {
      this.api.post(environment.searchUrl,
        {
          suggest : {
            'content-suggest' : {
              text : keyword,
              term: {
                  field : 'content',
                  min_word_length: 3
              }
            }
        }
      }).subscribe(result => {
        observer.next(result.suggest['content-suggest'][0].options);
      });
    }
  });
  }

  public getFile(lang: string, code: string): Observable<any> {
    return this.api.post(environment.graphQlUrl,
      {
        query: getFileFullGQL.replace('$lang', lang).replace('$code', code)
      }
    );
  }

  public getGame(lang: string, slug: string): Observable<any> {
    return this.api.post(environment.graphQlUrl,
      {
        query: getGameFullGQL.replace('code_langue', lang).replace('code_slug', slug)
      }
    );
  }

  public getCities() {
    return this.firestore.collection('cities').get();
  }

  public getSchools(city: any) {
    return this.firestore.collection('schools', ref => ref.where('city', '==', city)).get();
  }

  public getRecommendations(userId: string = '0', grade?: number, codeFile?: string) {
    let url = environment.functionsUrl + '/recommendation/?userID=' + userId;
    if (grade) {
      url = url + '&grade=' + grade;
    }
    if (codeFile) {
      url = url + '&ficheID=' + codeFile;
    }
    return this.api.get(url).pipe(
      map(x  => this.resultsToTiles(x))
    );
  }

  public resultsToTiles(results) {
    return results.map(item => {
      return {
        type: item.type,
        ...(item.ap_subtype && { subtype: item.ap_subtype }),
        title: item.title,
        topic: item.ap_topic,
        tags: item.tags,
        levels: this.getLevels(item),
        url: this.buildUrl(item),
        ...(item.image && { image: item.image }),
        ...(item.description && { description: item.description }),
        ...(item.variant && { variant: item.variant }),
        ...(item.featured && { featured: item.featured }),
        ...(item.thematic && { thematic: item.thematic }),
        ...(item.appUrls && { appUrls: item.appUrls }),
        ...(item.ap_moduleSlug && { moduleSlug: item.ap_moduleSlug }),
        ...(item.slug && item.type === 'exercise' && { entitySlug: item.slug }), // on retourne un entitySlug pour les exercices seulement
        ...(item.slug && item.type === 'video' && { moduleSlug: item.slug }), // on retourne un entitySlug pour les exercices seulement
        ...(item.ap_parentTitle && { parentTitle: item.ap_parentTitle })
      };
    });
  }

  private buildUrl(item) {
    // La url interne pour les jeux est différent des autres types
    if(item.type === 'game') {
      return `/bv/jeux/${item.slug}`;
    } else {
      const topic = item.ap_topic.startsWith('revision') ? 'revisions' : TopicVal[item.ap_topic];
      const slugUrl = ['exercise', 'video'].includes(item.type) ? `${item.parent_slug}-${item.parent_code}` : `${item.slug}-${item.ap_code}`;

      return  `/bv/${topic}/${slugUrl}`;
    }
  }

  public getTotalResultsByTypeAndTopic(
    lang: string,
    keywords: string = null
  ): Observable<any> {
    if (keywords) {
      return new Observable((observer) => {
        const elasticQuery = {
          size : 0,
          aggs : {
            countByTopic : {
              terms : {
                field : 'ap_topic'
              }
            },
            countByType : {
              terms : {
                field : 'type'
              }
            },

            countBySubType : {
              terms : {
                field : 'ap_subtype'
              }
            }
          },
          query: {
            bool: {
              must: [{
                match: { lang },
              },
              {
                bool: {
                    should: [
                      { match: { title: keywords }},
                      { match: { content: keywords }}
                    ]
                  }
                }
              ]
            }
          }
        };
        this.api.post(environment.searchUrl, elasticQuery).subscribe(result => {
          return observer.next(this.generateGlobalSearchResult(result));
        }, err => {
          return observer.error(err);
        });
      });
    }
  }

  private generateGlobalSearchResult(result) {
    const groupsByTopic = result.aggregations.countByTopic.buckets.map(el => {
      return {
        topic: el.key,
        size: el.doc_count
      };
    });
    const groupsByType = result.aggregations.countByType.buckets.map(el => {
      return {
        type: el.key,
        size: el.doc_count
      };
    });

    const groupsBySubType = result.aggregations.countBySubType.buckets.map(el => {
      return {
        type: el.key,
        size: el.doc_count
      };
    });
    return {
      size: result.hits.total.value,
      byTopic: groupsByTopic,
      byType: groupsByType,
      bySubType: groupsBySubType
    };
  }
}
